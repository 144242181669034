"use client";
import React from "react";
import Head from "next/head";
import { Stack, Paper } from "@mui/material";
import { LoginComposite } from "../../../components/composites";
import { useWindowDimensions } from "@cbex/utils/hooks";
import { useTranslations } from "next-intl";

export default function LoginPage() {
  const windowSize = useWindowDimensions();
  const t = useTranslations();
  return (
    <>
      <Head>
        <title>{t("common.myCBEX")}</title>
      </Head>
      <Stack
        paddingTop={10}
        alignItems="center"
        height={windowSize.height}
        width="100%"
        id="LoginPage"
      >
        <Paper>
          <LoginComposite />
        </Paper>
      </Stack>
    </>
  );
}
